<template>
  <footer class="px-4 py-4 text-white bg-secondary bg-primary-dark">
    <div class="container mx-auto">
      <div class="flex flex-wrap justify-between">
        <div
          class="order-last w-full mb-4 text-center md:mb-0 md:order-first md:text-left md:w-1/3"
        >
          <h2 class="block mt-6 text-xl text-white md:mt-0 md:mb-4 md:text-3xl opacity-60">
            {{ $t("contact") }}
          </h2>
          <span class="block">T +32 470 781 229</span>
          <a class="text-primary" href="mailto:info@nanac.be">info@nanac.be</a>
        </div>
        <div class="w-full text-center md:text-right md:w-1/3">
          <div class="flex justify-center mb-6 md:mb-4 md:justify-end">
            <LogoFrSVG class="h-10" v-if="$i18n.locale === 'fr'" />
            <LogoEnSVG class="h-10" v-else-if="$i18n.locale === 'en'" />
            <LogoNlSVG class="h-10" v-else />
          </div>
          <div class="text-center md:text-right opacity-60 leading-tight mb-2">
            <span class="block">{{ $t("footer.address.l1") }}</span>
            <span class="block">{{ $t("footer.address.l2") }}</span>
            <span class="block">{{ $t("footer.address.l3") }}</span>
            <span class="block">{{ $t("footer.address.l4") }}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        flex
        justify-center
        py-6
        space-x-4
        border-t-2 border-b-2 border-white border-opacity-20
      "
    >
      <a
        href="https://www.instagram.com/nanac_be/"
        target="_blank"
        class="transition-all opacity-80 hover:opacity-100"
      >
        <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <g>
            <path
              d="M256,49.471c67.266,0,75.233.256,101.8,1.468,24.562,1.121,37.9,5.225,46.779,8.674a78.07,78.07,0,0,1,28.966,18.845,78.07,78.07,0,0,1,18.845,28.966c3.449,8.877,7.553,22.217,8.674,46.778,1.212,26.565,1.468,34.532,1.468,101.8s-.256,75.233-1.468,101.8c-1.121,24.562-5.225,37.9-8.674,46.779a83.431,83.431,0,0,1-47.811,47.811c-8.878,3.449-22.217,7.553-46.779,8.674-26.56,1.212-34.527,1.468-101.8,1.468s-75.237-.256-101.8-1.468c-24.562-1.121-37.9-5.225-46.779-8.674a78.07,78.07,0,0,1-28.966-18.845,78.062,78.062,0,0,1-18.845-28.966c-3.449-8.878-7.553-22.217-8.674-46.778-1.212-26.565-1.468-34.532-1.468-101.8s.256-75.233,1.468-101.8c1.121-24.562,5.225-37.9,8.674-46.779A78.07,78.07,0,0,1,78.458,78.458a78.07,78.07,0,0,1,28.966-18.845c8.877-3.449,22.217-7.553,46.778-8.674,26.565-1.212,34.532-1.468,101.8-1.468m0-45.392c-68.418,0-77,.29-103.866,1.516-26.815,1.224-45.127,5.482-61.152,11.71a123.485,123.485,0,0,0-44.62,29.057,123.485,123.485,0,0,0-29.057,44.62C11.077,107.007,6.819,125.319,5.6,152.134,4.369,179,4.079,187.582,4.079,256s.29,77,1.516,103.866c1.224,26.815,5.482,45.127,11.71,61.152a123.493,123.493,0,0,0,29.057,44.62A123.485,123.485,0,0,0,90.982,494.7c16.025,6.228,34.337,10.486,61.152,11.71C179,507.631,187.582,507.921,256,507.921s77-.29,103.866-1.516c26.815-1.224,45.127-5.482,61.152-11.71A128.82,128.82,0,0,0,494.7,421.018c6.228-16.025,10.486-34.337,11.71-61.152C507.631,333,507.921,324.418,507.921,256s-.29-77-1.516-103.866c-1.224-26.815-5.482-45.127-11.71-61.152a123.485,123.485,0,0,0-29.057-44.62,123.485,123.485,0,0,0-44.62-29.057C404.993,11.077,386.681,6.819,359.866,5.6,333,4.369,324.418,4.079,256,4.079Z"
              fill="#e4e4e4"
            />
            <path
              d="M256,126.635A129.365,129.365,0,1,0,385.365,256,129.365,129.365,0,0,0,256,126.635Zm0,213.338A83.974,83.974,0,1,1,339.974,256,83.974,83.974,0,0,1,256,339.973Z"
              fill="#e4e4e4"
            />
            <circle cx="390.476" cy="121.524" r="30.23" fill="#e4e4e4" />
          </g>
        </svg>
      </a>
      <a
        href="https://twitter.com/nanac_be"
        target="_blank"
        class="transition-all opacity-80 hover:opacity-100"
      >
        <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 204">
          <path
            d="M221.952,51.287c.147,2.166.147,4.342.147,6.528,0,66.732-50.8,143.685-143.685,143.685v-.04a142.964,142.964,0,0,1-77.4-22.644,102.754,102.754,0,0,0,12.021.728,101.328,101.328,0,0,0,62.716-21.66,50.557,50.557,0,0,1-47.18-35.07,50.406,50.406,0,0,0,22.8-.866,50.518,50.518,0,0,1-40.514-49.5v-.64a50.12,50.12,0,0,0,22.921,6.321A50.536,50.536,0,0,1,18.14,10.714,143.372,143.372,0,0,0,122.217,63.476a50.546,50.546,0,0,1,86.059-46.057A101.213,101.213,0,0,0,240.343,5.161a50.672,50.672,0,0,1-22.2,27.932,100.784,100.784,0,0,0,29-7.946A102.748,102.748,0,0,1,221.952,51.287Z"
            fill="#e4e4e4"
          />
        </svg>
      </a>
    </div>
    <div class="block mt-4 text-xs text-center opacity-60">
      <a href="">Copyright {{ new Date().getFullYear() }}</a> | <a href="">Privacy Policy</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      menu: false,
    };
  },
};
</script>
