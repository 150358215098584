<template>
  <section class="bg-primary-dark py-12 pt-20 relative">
    <div
      :class="{ 'opacity-100 pointer-events-auto': modal, 'opacity-0 pointer-events-none': !modal }"
      class="
        max-w-lg
        w-full
        bg-white
        rounded-md
        p-10
        transition-all
        z-40
        fixed
        left-1/2
        top-1/2
        transform
        -translate-x-1/2 -translate-y-1/2
      "
    >
      <button @click="closeModal" class="right-2 top-2 absolute">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 24 24">
          <path
            d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
          />
        </svg>
      </button>
      <div class="text-center" v-if="!modalValidated">
        <h1 class="text-3xl uppercase mb-2">{{ $t("newsletter.title") }}</h1>
        <p class="mb-3">{{ $t("newsletter.text") }}</p>
        <input
          class="
            border-b-primary border-b
            text-center text-2xl
            h-16
            mb-3
            block
            w-full
            bg-transparent
          "
          type="text"
          :placeholder="$t('newsletter.placeholder')"
        />
        <button
          @click="validateModal"
          class="
            p-4
            px-16
            text-xl
            mt-10
            bg-secondary-rose
            text-white
            font-semibold
            uppercase
            border-b-2 border-black border-opacity-20
          "
        >
          {{ $t("newsletter.button") }}
        </button>
      </div>
      <div class="text-center" v-else>
        <h1 class="text-3xl uppercase mb-2">{{ $t("newsletter.title2") }}</h1>
        <p>{{ $t("newsletter.text2") }}</p>
        <button
          @click="validateModal"
          class="
            p-4
            px-16
            text-xl
            mt-10
            bg-secondary-rose
            text-white
            font-semibold
            uppercase
            border-b-2 border-black border-opacity-20
          "
        >
          {{ $t("newsletter.button2") }}
        </button>
      </div>
    </div>
    <div class="container mx-auto">
      <h1 class="px-4 max-w-3xl xl:px-0 text-primary text-4xl my-16">{{ $t("home.subtitle") }}</h1>
      <div>
        <div id="consumer" class="mx-auto text-white text-xl">
          <h2 class="bg-secondary-oliva p-4 rounded-t-2xl text-white text-3xl">
            {{ $t("home.consumer") }}
          </h2>
          <div
            class="h-80"
            :style="{
              background: 'url(/img/nanac-img-consument.png)',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }"
          ></div>
          <div class="max-w-2xl px-4 xl:px-0">
            <div class="mt-10">
              <h2 class="text-2xl font-semibold mb-4 text-secondary-oliva">
                {{ $t("subpage.title-1") }}
              </h2>
              <p>{{ $t("subpage.p-1") }}</p>
            </div>
            <div class="mt-8">
              <h2 class="text-2xl font-semibold mb-4 text-secondary-oliva">
                {{ $t("subpage.title-2") }}
              </h2>
              <p class="mb-8">
                {{ $t("subpage.p-2") }}
                <a
                  class="text-primary underline"
                  href="https://www.eccbelgie.be/themas/onlineaankopen/doe-de-webshop-check"
                  target="_blank"
                  >{{ $t("subpage.a-1") }}</a
                >
                {{ $t("subpage.p-3") }}
                <a
                  class="text-primary underline"
                  href="https://www.eccbelgie.be/themas/oneerlijke-praktijken/namaak-en-piraterij"
                  target="_blank"
                  >{{ $t("subpage.a-2") }}</a
                >
                {{ $t("subpage.p-4") }}
              </p>
              <p>
                {{ $t("subpage.p-5") }}
                <a
                  class="text-primary underline"
                  href="https://consumentenombudsdienst.be/nl"
                  target="_blank"
                  >{{ $t("subpage.a-3") }}</a
                >
                {{ $t("subpage.p-6") }}
                <a
                  class="text-primary underline"
                  href="https://www.ombudsmanvoordehandel.be/nl"
                  target="_blank"
                  >{{ $t("subpage.a-4") }}</a
                >
              </p>
            </div>
            <div class="mt-8">
              <h2 class="text-2xl mb-4 font-semibold text-secondary-oliva">
                {{ $t("subpage.title-3") }}
              </h2>
              <p>
                {{ $t("subpage.p-7") }}
                <a
                  class="text-primary underline"
                  href="https://financien.belgium.be/nl/douane_accijnzen/particulieren/reizen/namaak"
                  target="_blank"
                  >{{ $t("subpage.a-5") }}</a
                >,
                <a
                  class="text-primary underline"
                  href="https://economie.fgov.be/nl/over-de-fod/structuur-fod-economie/organigrammen/de-algemene-directie"
                  target="_blank"
                  >{{ $t("subpage.a-6") }}</a
                >
                {{ $t("subpage.p-8") }}
                <a
                  class="text-primary underline"
                  href="https://www.abac-baan.be/nl/over_ons"
                  target="_blank"
                  >{{ $t("subpage.a-7") }}</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="mx-auto text-white text-xl mt-20">
          <h2 id="business" class="bg-secondary-rose p-4 rounded-t-2xl text-white text-3xl">
            {{ $t("home.business") }}
          </h2>
          <div
            class="h-80"
            :style="{
              background: 'url(/img/nanac-img-bedrijf.png)',
              backgroundSize: 'cover',
            }"
          ></div>
          <div class="max-w-2xl px-4 xl:px-0">
            <div class="mt-10">
              <h2 class="text-2xl mb-4 font-semibold text-secondary-rose">
                {{ $t("business.title-1") }}
              </h2>
              <p class="mb-8">{{ $t("business.p-1") }}</p>
              <p>
                {{ $t("business.p-2") }}
                <a class="text-primary underline" href="https://www.iccwbo.be/" target="_blank">{{
                  $t("business.a-1")
                }}</a>
                {{ $t("business.p-3") }}
              </p>
            </div>
            <div class="mt-8">
              <h2 class="text-2xl mb-4 font-semibold text-secondary-rose">
                {{ $t("business.title-2") }}
              </h2>
              <p>
                {{ $t("business.p-4") }}
                <a
                  href="https://financien.belgium.be/nl/douane_accijnzen/ondernemingen"
                  target="_blank"
                  class="text-primary underline"
                >
                  {{ $t("business.a-2") }}</a
                >,
                <a
                  href="https://economie.fgov.be/nl/themas/intellectuele-eigendom/naleving-van-de-intellectuele/optreden-tegen-namaak"
                  target="_blank"
                  class="text-primary underline"
                >
                  {{ $t("business.a-3") }}</a
                >,
                <a
                  href="https://www.politie.be/5998/nl/contact/online-aangiften-niet-dringend/meldpunt-fraude"
                  target="_blank"
                  class="text-primary underline"
                >
                  {{ $t("business.a-4") }}</a
                >
                {{ $t("business.p-5") }}
                <a
                  href="https://www.abac-baan.be/nl/over_ons"
                  target="_blank"
                  class="text-primary underline"
                >
                  {{ $t("business.a-5") }}</a
                >
              </p>
            </div>
            <div class="mt-8">
              <h2 class="text-2xl relative pl-7 mb-4 font-semibold text-secondary-rose">
                <img class="absolute left-0 top-2 w-5 h-5 mr-2" src="/img/nanac-number-01.svg" />
                {{ $t("business.step-1.title") }}
              </h2>
              <p>{{ $t("business.step-1.p-1") }}</p>
              <p class="block">{{ $t("business.step-1.p-1-5") }}</p>
              <span class="relative pl-5"
                ><div class="absolute left-0 w-3 mt-2 mr-2 overflow-hidden inline-block">
                  <div class="h-8 bg-white rotate-45 transform origin-top-left"></div>
                </div>
                {{ $t("business.step-1.li-1") }}</span
              >
              <span class="relative w-full block pl-5"
                ><div class="absolute left-0 w-3 mt-2 mr-2 overflow-hidden inline-block">
                  <div class="h-8 bg-white rotate-45 transform origin-top-left"></div>
                </div>
                {{ $t("business.step-1.li-2") }}</span
              >
              <p class="mt-4">{{ $t("business.step-1.p-2") }}</p>
              <p class="mt-4">{{ $t("business.step-1.p-3") }}</p>
              <a
                href="https://financien.belgium.be/sites/default/files/Customs/Brochures/Brochure%20Namaak_2021_NL_A5.pdf"
                target="_blank"
                class="flex text-primary underline"
                ><div class="w-3 mt-2 mr-2 overflow-hidden inline-block">
                  <div class="h-8 bg-primary rotate-45 transform origin-top-left"></div>
                </div>
                {{ $t("business.step-1.li-4") }}</a
              >
              <a
                href="https://financien.belgium.be/sites/default/files/Customs/Brochures/Brochure%20Namaak_2021_FR_A5.pdf"
                target="_blank"
                class="flex text-primary underline"
                ><div class="w-3 mt-2 mr-2 overflow-hidden inline-block">
                  <div class="h-8 bg-primary rotate-45 transform origin-top-left"></div>
                </div>
                {{ $t("business.step-1.li-5") }}</a
              >
              <a
                href="https://financien.belgium.be/sites/default/files/Customs/Brochures/Brochure%20Namaak_2021_DTS_A5.pdf"
                target="_blank"
                class="flex text-primary underline"
                ><div class="w-3 mt-2 mr-2 overflow-hidden inline-block">
                  <div class="h-8 bg-primary rotate-45 transform origin-top-left"></div>
                </div>
                {{ $t("business.step-1.li-6") }}</a
              >
              <a
                class="text-primary underline font-semibold"
                href="https://financien.belgium.be/nl/douane_accijnzen/ondernemingen"
                target="_blank"
                >{{ $t("business.step-1.a-1") }}</a
              >
              <div class="mt-6">
                <h2
                  class="
                    text-2xl
                    mb-4
                    relative
                    pl-7
                    font-semibold
                    flex
                    items-center
                    text-secondary-rose
                  "
                >
                  <img
                    class="absolute block left-0 top-2 w-5 h-5 mr-2"
                    src="/img/nanac-number-02.svg"
                  />
                  {{ $t("business.step-1.title") }}
                </h2>
                <p>{{ $t("business.step-2.p-1") }}</p>
                <a
                  class="text-primary underline"
                  href="https://economie.fgov.be/nl/themas/intellectuele-eigendom/naleving-van-de-intellectuele/optreden-tegen-namaak"
                  target="_blank"
                  >{{ $t("business.step-2.a-1") }}</a
                >
              </div>
              <div class="mt-6">
                <h2 class="text-2xl mb-4 font-semibold relative pl-7 text-secondary-rose">
                  <img
                    class="absolute block left-0 top-2 w-5 h-5 mr-2"
                    src="/img/nanac-number-03.svg"
                  />
                  {{ $t("business.step-3.title") }}
                </h2>
                <p>
                  {{ $t("business.step-3.p-1") }}
                  <a
                    class="text-primary underline"
                    href="https://meldpunt.belgie.be/meldpunt/"
                    target="_blank"
                    >{{ $t("business.step-3.a-1") }}</a
                  >
                  {{ $t("business.step-3.p-2") }}
                  <a
                    class="text-primary underline block mt-4"
                    href="https://www.politie.be/5998/nl/contact"
                    target="_blank"
                    >{{ $t("business.step-3.a-2") }}</a
                  >
                </p>
              </div>
              <div class="mt-6">
                <h2
                  class="
                    text-2xl
                    pl-7
                    relative
                    mb-4
                    font-semibold
                    flex
                    items-center
                    text-secondary-rose
                  "
                >
                  <img class="w-5 h-5 mr-2 absolute left-0 top-2" src="/img/nanac-number-04.svg" />
                  {{ $t("business.step-4.title") }}
                </h2>
                <p>
                  {{ $t("business.step-4.p-1") }}
                  <a
                    class="text-primary underline block mt-4"
                    href="https://www.abac-baan.be/nl/interventies"
                    target="_blank"
                    >{{ $t("business.step-4.b-1") }}</a
                  >
                </p>
              </div>
              <button
                class="
                  special
                  relative
                  bg-secondary-rose
                  uppercase
                  text-xl text-white
                  flex
                  w-full
                  max-w-[400px]
                  mt-6
                  border-b-2 border-black border-opacity-20
                  justify-between
                  items-center
                  p-3
                "
                @click="openModal"
              >
                {{ $t("business.step-3.b-1") }}
                <ArrowSVG class="relative z-30 fill-current h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
        <div class="mx-auto text-white text-xl mt-20">
          <h2 id="service_provider" class="bg-secondary-gold p-4 rounded-t-2xl text-white text-3xl">
            {{ $t("home.service-provider") }}
          </h2>
          <div
            class="h-80"
            :style="{
              background: 'url(/img/nanac-img-dienstverlener.png)',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }"
          ></div>
          <div class="max-w-2xl px-4 xl:px-0">
            <div class="mt-10">
              <p class="mb-6">
                {{ $t("service_provider.p-1") }}
                <a
                  class="text-primary underline"
                  href="https://financien.belgium.be/nl/douane_accijnzen/ondernemingen"
                  target="_blank"
                  >{{ $t("service_provider.a-1") }}</a
                >
              </p>
              <p>
                {{ $t("service_provider.p-2") }}
                <a class="text-primary underline" href="https://www.iccwbo.be/" target="_blank">{{
                  $t("service_provider.a-2")
                }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </section>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
  name: "Business",
  components: {
    Footer,
  },
  data() {
    return {
      modal: false,
      modalValidated: false,
    };
  },
  methods: {
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      this.modalValidated = false;
    },
    validateModal() {
      this.modalValidated = true;
      //do smth here
    },
  },
};
</script>
